import foto1 from '../../assets/img/experience/lake1.jpg';
import foto2 from '../../assets/img/experience/lake2.jpg';

import './experience.scss';

export const ExperiencePage = () => (
  <div className='experience-wrapper'>
    <h3 className='title'>Впечатления гостей</h3>
    <div className='review'>
      <img src={foto1} alt='family' className='foto-1'/>
      <p>
        Однажды летним утром наша семья, состоящая из меня, моей жены Лены и наших двух прекрасных дочек - двухлетней
        Кати и пятилетней Светы, решила отправиться в глэмпинг на берегу озера, окруженного величественным сосновым
        лесом. Мы были в восторге от красоты природы, окружающей нас. Сосновые деревья тянулись к небу, создавая
        приятную тень, а аромат хвои наполнял воздух свежестью. Наша палатка была расположена прямо у берега озера, и мы
        могли наслаждаться потрясающим видом каждое утро, просыпаясь под пение птиц. В глэмпинге было множество
        развлечений, подходящих для нас и наших девочек. Мы проводили дни, играя в настольные игры, катаясь на
        велосипедах по обширной территории и исследуя красивые тропинки, ведущие через лес. Катя с улыбкой каталась на
        своем маленьком велосипеде, а Света с энтузиазмом следовала за ней, обгоняя по пути маленьких бабочек и цветы.
        Мы также решили попробовать рыбалку. Взяв удочки в аренду, мы отправились на прогулку по озеру.
      </p>
      <img src={foto2} alt='' className='foto-2'/>
      <p>
        Света была взволнована, когда поймала свою первую рыбку, а Катя с интересом наблюдала, как рыбки плавают в воде.
        Мы отпустили пойманных рыбок, радуясь успешному дню на рыбалке. Вечерами мы собирались вокруг костра, где
        готовили вкусные шашлыки и пекли закуски на открытом огне. Девочки помогали нам собирать дрова и наслаждались
        теплом и уютом, сидя рядом с нами. Мы проводили время, рассказывая друг другу истории и наблюдая, как звезды
        появляются на ночном небе. Каждую ночь мы засыпали под шум прибоя и пение кузнечиков, чувствуя себя окруженными
        природой и её спокойствием. Это был незабываемый отдых для нашей семьи, где мы наслаждались красотой окружающего
        нас мира и проводили время вместе, создавая прекрасные воспоминания, которые останутся с нами на всю жизнь.
      </p>
    </div>
  </div>
);
