import { useEffect } from 'react';

import { ModalContactUs } from '../../components/form/form';

import './contact.scss';

export const ContactPage = () => {
  useEffect(() => {
    document.title = 'Almarana - о нас';
  });

  return (
    <div className='content-wrapper'>
      <h3 className='page-title'>О нас</h3>
      <div className='map-wrapper'>
        <div className='map'>
          <iframe
            title='map'
            src='https://yandex.by/map-widget/v1/?ll=30.062976%2C56.144833&mode=poi&poi%5Bpoint%5D=30.064020%2C56.143905&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D33218792479&z=18.43'
            // allowfullscreen='true'
            className='map-frame'
          />
        </div>
      </div>
      <div className='contacts-wrapper'>
        <p className='title'>Контакты</p>
        <p>Адрес: Псковская область, Невельский муниципальный округ, Ивановская волость</p>
        <p>Координаты: 56.143899, 30.064022</p>
        <div className='phone'>
          <p>Телефон для связи: +</p>
          <a href='tel:+79119379992' className='phone-number'>
            7 (911) 937-99-92
          </a>
        </div>
      </div>
      <div>
        {/* <ModalContactUs /> */}
      </div>
    </div>
  );
};
