import me from '../../assets/img/guest-experience/beeart.jpg';
import fieldBee from '../../assets/img/guest-experience/field-bee.jpg';

import './block-guest-experience.scss';

export const GuestExperience = () => {
  const x = 1;

  return (
    <div className='experience'>
      <div>
        <p className='title'>Серия моментов, прожитых и увиденных нашими первыми гостями</p>
        <p className='text'>
          У отеля есть огромное преимущество - это его территория. Она очень большая и ухоженная. Много красивой зелени.
          На территории есть три бассейна, аквапарк, театр, терраса со столиками для приятного вечернего
          времяпровождения, три ресторана, магазины, детский клуб, бесплатный спортзал и платный спа -салон. Меню
          ресторана отличное, каждый турист мог себе выбрать все по своему вкусу. Анимация отличная. Ребята отличные
          трудяги. Такое впечатление что они...
        </p>
        <a href=''>Подробнее</a>
      </div>
      <img src={me} alt='me' className='picSmall' />
      <img src={fieldBee} alt='field' className='picBig' />
      {/* <button type='button'>Подробнее</button> */}
    </div>
  );
};

// Отдых наш длился 9 дней. Конечно мы пожалели потом что не прилетели на 2 недели. На рецепшене нас встретили улыбкой и хорошим настроением. У отеля есть огромное преимущество - это его территория. Она очень большая и ухоженная. Много красивой зелени. На территории есть три бассейна, аквапарк, театр, терраса со столиками для приятного вечернего времяпровождения, три ресторана, магазины, детский клуб, бесплатный спортзал и платный спа -салон. Меню ресторана отличное, каждый турист мог себе выбрать все по своему вкусу. Анимация отличная. Ребята отличные трудяги. Такое впечатление что они работают постоянно. Всегда уделяют внимание туристу, всех стараются развеселить. Есть отдельный детский аниматор. Занимается с детками в детской комнате, ходят на прогулку, за мороженным, вечером дискотеки для детишек. Для взрослых караоке, камеди шоу, дискотеки, живая музика. Большой плюс отеля его аквапарк. Приезжайте сюда и наслаждайтесь замечательным отдыхом!!!
