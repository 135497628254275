import { NavLink } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';

import './navigation-link.scss';

export const NavigationLink = () => (
  <div className='navigation-links'>
    {/* <NavLink to={ROUTES.home} className='page-link'>
      Главная
    </NavLink>
    <NavLink to={ROUTES.aboutUs} className='page-link'>
      О нас
    </NavLink>
    <NavLink to={ROUTES.houses} className='page-link'>
      Домики
    </NavLink>
    <NavLink to={ROUTES.terms} className='page-link'>
      Правила
    </NavLink>
    <NavLink to={ROUTES.contacts} className='page-link'>
      Контакты
    </NavLink> */}
    {/* <NavLink to='/' className='page-link'>
      Главная
    </NavLink> */}
    <NavLink to='/experience' className='page-link'>Впечатления</NavLink>
    <NavLink to='/package-tours' className='page-link'>Пакетные туры</NavLink>
    <NavLink to='/houses' className='page-link'>
      Дома
    </NavLink>
    <NavLink to='/occasions' className='page-link'>События</NavLink>
    <NavLink to='/offers' className='page-link'>Предложения</NavLink>
    <NavLink to='/about-us' className='page-link'>О нас</NavLink>
    {/* <NavLink className='page-link'>Правила</NavLink> */}
  </div>
);
