import { useState } from 'react';

import burgerClose from '../../assets/icon/burger-close.svg';
import burgerLines from '../../assets/icon/burger-lines.svg';
import { NavigationLink } from '../navigation/navigation-link';

import './burger.scss';

export const Burger = () => {
  const [isOpenBurger, setIsOpenBurger] = useState(false);

  const change = () => {
    setIsOpenBurger(!isOpenBurger);
  };

  return (
    <div className={isOpenBurger ? 'burger open' : 'burger'}>
      {isOpenBurger ? (
        <button type='button' onClick={change} className='burger-button-close'>
          <img src={burgerClose} alt='' />
        </button>
      ) : (
        <button type='button' onClick={change} className='burger-button'>
          <img src={burgerLines} alt='' />
        </button>
      )}
      {isOpenBurger ? <NavigationLink /> : ''}
    </div>
  );
};
