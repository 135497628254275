import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import bathhouse from '../../assets/img/offers/bathhouse-spa.jpg';
import boat from '../../assets/img/offers/boat-lake.jpg';
import fishing from '../../assets/img/offers/fishing.jpg';
import forest from '../../assets/img/offers/forest.jpg';
import games from '../../assets/img/offers/inclined-wooden-block-tower-jenga-game-with-hand-risk-concept-free-photo.jpg';
import cycle from '../../assets/img/offers/rent-cycle.jpg';

import './offers.scss';

export const OffersPage = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const blockRefs = {
    block1: useRef(null),
    block2: useRef(null),
    block3: useRef(null),
    block4: useRef(null),
    block5: useRef(null),
    block6: useRef(null),
  };

  const location = useLocation();

  useEffect(() => {
    document.title = 'Almarana - наши предложения';
    const searchParams = new URLSearchParams(location.search);
    const blockId = searchParams.get('blockId');

    if (blockId && blockRefs[blockId].current) {
      blockRefs[blockId].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [blockRefs, location]);

  return (
    <section className='offers-page'>
      <h3 className='page-title'>Наши услуги</h3>
      <div className='offers-content'>
        <div className='offer' id='section1' ref={blockRefs.block1}>
          <img src={bathhouse} alt='bathhouse' className='offer-image' />
          <div>
            <p className='offer-title'>Баня</p>
            <p className='offer-description'>
              Отдыхайте в уединенной русской бане, расположенной на берегу живописного озера. Насладитесь аутентичной
              атмосферой парной, погружаясь в теплые волны пара и наслаждаясь благотворными процедурами от прекрасной
              бани. После парения окунитесь в озеро для освежающего ощущения и полного расслабления. Вам гарантированы
              моменты истинного умиротворения и наслаждения в этом уголке спокойствия.
            </p>
          </div>
        </div>
        <div className='offer' id='section2' ref={blockRefs.block2}>
          <img src={boat} alt='boat-lake' className='offer-image' />
          <div>
            <p className='offer-title'>Лодки</p>
            <p className='offer-description'>
              Исследуйте великолепие озера с нашей услугой аренды лодок. Возьмите в аренду нашу комфортабельную лодку и
              отправляйтесь в незабываемое приключение по озеру. Насладитесь тишиной и красотой окружающей природы,
              поймайте рыбу или просто расслабьтесь на воде. Вас ждет незабываемый опыт и неповторимые впечатления от
              озера.
            </p>
          </div>
        </div>
        <div className='offer' id='section3' ref={blockRefs.block3}>
          <img src={fishing} alt='fishing' className='offer-image' />
          <div>
            <p className='offer-title'>Рыбалка</p>
            <p className='offer-description'>
              Отправьтесь на увлекательную рыбалку в нашем прекрасном месте. Наше озеро изобилует разнообразными видами
              рыбы, предлагая вам возможность поймать уникальную трофейную рыбу. Насладитесь спокойствием природы,
              удилищем в руках и ожиданием удачного улова. Погрузитесь в мир рыболовства и создайте незабываемые
              воспоминания, наслаждаясь рыбалкой на нашем озере.
            </p>
          </div>
        </div>
        <div className='offer' id='section4' ref={blockRefs.block4}>
          <img src={forest} alt='forest' className='offer-image' />
          <div>
            <p className='offer-title'>Прогулки</p>
            <p className='offer-description'>
              Отправляйтесь в увлекательные прогулки по прекрасному хвойному лесу. Изучайте его магическую атмосферу,
              где вас окружают густые сосны, создавая уникальную ауру спокойствия и свежести. Прогулки по лесу позволят
              вам насладиться свежим воздухом, красотой природы и погрузиться в умиротворяющую обстановку, которая
              освободит вас от повседневных забот и оставит лишь благодатные впечатления.
            </p>
          </div>
        </div>
        <div className='offer' id='section5' ref={blockRefs.block5}>
          <img src={games} alt='games' className='offer-image' />
          <div>
            <p className='offer-title'>Настольные игры</p>
            <p className='offer-description'>
              Раскройте веселье и соревновательный дух с нашим разнообразным выбором настольных игр. Независимо от того,
              предпочитаете ли вы стратегические игры, вечеринки с командными сражениями или умопомрачительные
              головоломки, у нас есть игра, которая подойдет вашим предпочтениям. Соберите друзей или семью вокруг стола
              и создайте незабываемые моменты смеха, соперничества и взаимодействия. Откройте новые миры развлечения с
              нашими настольными играми и погрузитесь в увлекательные приключения в нашем комфортном пространстве.
            </p>
          </div>
        </div>
        <div className='offer' id='section6' ref={blockRefs.block6}>
          <img src={cycle} alt='cycle' className='offer-image' />
          <div>
            <p className='offer-title'>Велопрокат</p>
            <p className='offer-description'>
              Отправляйтесь в увлекательную велопрогулку с нашим велопрокатом. Наслаждайтесь комфортом и свежим
              воздухом, выбрав подходящий велосипед. Просто возьмите его в аренду и наслаждайтесь приятной прогулкой в
              своем темпе.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
