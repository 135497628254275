import { useEffect, useState } from 'react';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import slide3 from '../../assets/img/1333963.jpg';
import slide4 from '../../assets/img/beach.jpg';
import slide5 from '../../assets/img/original.jpg';
import slide2 from '../../assets/img/skyview.jpg';
import slide1 from '../../assets/img/sunset.jpg';

import './about-us.scss';
import './swiper-style.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';

export const AboutUsPage = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = 'Almarana - о нас';

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className='about-us-wrapper'>
      <h3 className='page-title'>О нас</h3>
      <div className='about-wrapper'>
        <div className='about-text'>
          <p>
            Мы находится в одном из живописнейших мест на берегу озера Большой Иван. Среди озёр и сосен мы создали
            комфортное, гармонично вписанное в природный ландшафт место для отдыха и приключений! Лесная поляна нашего
            кэмпа - отдельный мир, где царят спокойствие и комфорт. Все домики находятся в лесу вокруг поляны и у
            каждого есть удобное кострище со скамейками, где можно провести время в узком кругу, уединившись от
            посторонних глаз. Всего 100 метров красивой лесной тропы отделяет наш кэмп от озера. На озере оборудован
            удобный пирс. О потрясающих видах на самом озере лучше всего скажут фотографии, хотя и они не способны
            передать эту великолепную красоту. Обязательно приезжайте в гости, насладитесь природой и спокойствием!
          </p>
        </div>
      </div>
      {screenWidth > 1024 ? (
        <div className='about-slider'>
          <Swiper
            slidesPerView={3}
            spaceBetween={100}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className='mySwiper'
          >
            <SwiperSlide>
              <img src={slide1} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide2} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide3} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide4} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide5} alt='sl1' />
            </SwiperSlide>
          </Swiper>
        </div>
      ) : (screenWidth > 680 ? (
        <div className='about-slider'>
          <Swiper
            slidesPerView={2}
            spaceBetween={100}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className='mySwiper'
          >
            <SwiperSlide>
              <img src={slide1} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide2} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide3} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide4} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide5} alt='sl1' />
            </SwiperSlide>
          </Swiper>
        </div>
      ) : (
        <div className='about-slider'>
          <Swiper
            slidesPerView={1}
            spaceBetween={100}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className='mySwiper'
          >
            <SwiperSlide>
              <img src={slide1} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide2} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide3} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide4} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide5} alt='sl1' />
            </SwiperSlide>
          </Swiper>
        </div>
      ))}
    </div>
  );
};
