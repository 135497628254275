/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { Calendar } from '../calendar/calendar';

import 'react-datepicker/dist/react-datepicker.css';
import './form.scss';

export const ModalContactUs = ({ active, setActive, apartment }) => {
  //   const [apartment, setApartment] = useState('');
  const [date, setDate] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const form = useRef();

  const handleDataUpdate = (newData) => {
    setDate(newData);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_1sihxi5', 'template_vrigm1p', form.current, 'NP-t0mRqURIvpcHD-').then(
      (result) => {
        // console.log('it`s work');
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const validateForm = () => {
    const errors = {};

    if (!date) {
      errors.date = 'Укажите дату';
    } else if (!/^[^A-Za-zА-Яа-я]+$/.test(date)) {
      errors.date = 'Используйте цифры';
    }

    if (!name) {
      errors.name = 'Необходимо заполнить Ф.И.О.';
    } else if (!/^[A-Za-zА-Яа-я ]+$/.test(name)) {
      errors.name = 'Можно использовать только буквы';
    }

    if (!email) {
      errors.email = 'Email обязателен';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Неверно записан Email';
    }

    if (!phone) {
      errors.phone = 'Телефон обязателен';
    } else if (!/^\d+$/.test(phone)) {
      errors.phone = 'Используйте только цифры';
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setActive(false);
    }

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      sendEmail(e);

      setDate('');
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setErrors({});
    }
  };

  return (
    <div
      className={active ? 'container active' : 'container'}
      onClick={() => {
        setActive(false);
      }}
    >
      <form
        ref={form}
        // onSubmit={sendEmail}
        onSubmit={handleSubmit}
        className='bea'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <label htmlFor='apartment'>Номер апартаментов #{apartment}</label>
          <input type='text' value={apartment} name='apartment_number' hidden={true} />
          {/* {errors.apartment && <span>{errors.apartment}</span>} */}
        </div>

        <div>
          <label htmlFor='date'>Дата заезда</label>
          <input type='text' value={date} name='date' hidden={true} />
          <Calendar onDataUpdate={handleDataUpdate} />
          {errors.date && <span>{errors.date}</span>}
        </div>

        <div>
          <label htmlFor='name'>Ф.И.О.</label>
          <input
            type='text'
            id='name'
            name='user_name'
            value={name}
            placeholder='Кот Иван Федорович'
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <span>{errors.name}</span>}
        </div>

        <div>
          <label htmlFor='email'>E-mail для связи</label>
          <input
            type='email'
            id='email'
            name='user_email'
            value={email}
            placeholder='kot_ivan@mail.ru'
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <span>{errors.email}</span>}
        </div>

        <div>
          <label htmlFor='phone'>Телефон для связи</label>
          <input
            id='phone'
            name='user_phone'
            value={phone}
            placeholder='79119007080'
            onChange={(e) => setPhone(e.target.value)}
          />
          {errors.phone && <span>{errors.phone}</span>}
        </div>

        <div>
          <label htmlFor='message'>Примечания</label>
          <textarea
            name='notes'
            value={message}
            placeholder='Ваши пожелания и вопросы'
            onChange={(e) => setMessage(e.target.value)}
          />
          {/* {errors.message && <span>{errors.message}</span>} */}
        </div>

        <button type='submit' className='btn-send'>
          Отправить
        </button>
      </form>
    </div>
  );
};
