import { useState } from 'react';
import DatePicker from 'react-datepicker';

import { formatDate } from '../../constants/format-date';

export const Calendar = ({ onDataUpdate }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onChangeDate = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
    onDataUpdate(`${formatDate(start)} - ${formatDate(end)}`);
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={onChangeDate}
      startDate={startDate}
      endDate={endDate}
      selectsRange='true'
      withPortal='true'
      portalId='root-portal'
    />
  );
};
