import house_1_1 from '../assets/houses/house_1/house_1_1.jpg';
import house_1_2 from '../assets/houses/house_1/house_1_2.jpg';
import house_1_3 from '../assets/houses/house_1/house_1_3.jpg';
import house_1_4 from '../assets/houses/house_1/house_1_4.jpg';
import house_1_5 from '../assets/houses/house_1/house_1_5.jpg';
import house_1_6 from '../assets/houses/house_1/house_1_6.jpg';
import house_1_7 from '../assets/houses/house_1/house_1_7.jpg';

import house_2_1 from '../assets/houses/house_2/house_2_1.jpg';
import house_2_2 from '../assets/houses/house_2/house_2_2.jpg';
import house_2_3 from '../assets/houses/house_2/house_2_3.jpg';

import house_3_1 from '../assets/houses/house_3/house_3_1.jpg';
import house_3_2 from '../assets/houses/house_3/house_3_2.jpg';
import house_3_3 from '../assets/houses/house_3/house_3_3.jpg';
import house_3_4 from '../assets/houses/house_3/house_3_4.jpg';
import house_3_5 from '../assets/houses/house_3/house_3_5.jpg';

import house_4_1 from '../assets/houses/house_4/house_4_1.jpg';
import house_4_2 from '../assets/houses/house_4/house_4_2.jpg';
import house_4_3 from '../assets/houses/house_4/house_4_3.jpg';
import house_4_4 from '../assets/houses/house_4/house_4_4.jpg';
import house_4_5 from '../assets/houses/house_4/house_4_5.jpg';

import house_5_1 from '../assets/houses/house_5/house_5_1.jpg';
import house_5_2 from '../assets/houses/house_5/house_5_2.jpg';
import house_5_3 from '../assets/houses/house_5/house_5_3.jpg';

export const PhotoHouse1 = [house_1_1, house_1_2, house_1_3, house_1_4, house_1_5, house_1_6, house_1_7]
export const PhotoHouse2 = [house_2_1, house_2_2, house_2_3]
export const PhotoHouse3 = [house_3_1, house_3_2, house_3_3, house_3_4, house_3_5]
export const PhotoHouse4 = [house_4_1, house_4_2, house_4_3, house_4_4, house_4_5]
export const PhotoHouse5 = [house_5_1, house_5_2, house_5_3]
