import { useEffect } from 'react';

import './terms.scss';

export const TermsPage = () => {
  useEffect(() => {
    document.title = 'Almarana - Правила';
  });

  return (
    <section className='terms-page'>
      <h3 className='page-title'>Положение о порядке проживания, пребывания и предоставления услуг</h3>
      <div className='term-content'>
        <div className='rules-content'>
          <div className='rules-info'>
            <p className='info'>Мы любим своих гостей и хотели бы, чтобы это было взамно</p>
            <p className='info'>Правил немного, но они важны</p>
            <p>Время заезда в глэмпинг — 14.00</p>
            <p>Время выезда — 11.00</p>
          </div>
          <ol className='rules-points'>
            <li>
              Основные положения:
              <ol>
                <li>
                  Под понятием `глэмпинг` следует понимать: территорию глэмпинга и другие объекты, обеспечивающие его
                  функционирование.
                </li>
                <li>
                  Под понятием `Гость глэмпинга` следует понимать лицо, находящееся на территории глэмпинга,
                  пользующееся услугами, включая услуги по проживанию.
                </li>
                <li>
                  Под понятием `Посетитель глэмпинга` следует понимать лицо, находящееся на территории глэмпинга,
                  пользующееся услугами, без услуги по проживанию, на основании чеков по оплате услуг глэмпинга,
                  подтверждающих основание правомерного нахождения на территории глэмпинга.
                </li>
                <li>
                  Под понятием `Оказанная услуга` следует понимать действие, произведенное сотрудниками глэмпинга по
                  выполнению взятых на себя обязательств в соответствии с законом `О защите прав потребителей` и другими
                  нормативными актами, регламентирующими различные виды деятельности.
                </li>
                <li>
                  Под понятием `Не оказанная услуга` следует понимать невыполнение сотрудниками глэмпинга взятых на себя
                  обязательств по предоставлению оплаченных услуг.
                </li>
                <li>
                  Под понятием `Не в полной мере оказанная услуга` следует понимать услугу, которая была оказана не во
                  время или с какими-либо отклонениями от заявленных характеристик.
                </li>
                <li>
                  Под понятием `Грубое нарушение правил проживания в глэмпинге` следует понимать ситуацию, при которой
                  поведение гостя препятствует администрации глэмпинга и его персоналу выполнять надлежащим образом свои
                  обязанности по обеспечению отдыха других гостей глэмпинга или его действия оскорбляют честь и
                  достоинство обслуживающего персонала, а также нарушает настоящие Правила и нормы законодательства РФ.
                </li>
                <li>
                  Под понятием `Исполнитель` следует понимать лицо, оказывающее услуги по размещению и проживанию в
                  глэмпинге `Almarana glamping village`.
                </li>
                <li>
                  Размещение в глэмпинге производится только на основании документа, удостоверяющего личность (паспорт).
                </li>
              </ol>
            </li>
            <li>
              Правила размещения:
              <ol>
                <li>
                  {' '}
                  Размещение в глэмпинге осуществляется на основании предъявления документов, удостоверяющих личность,
                  согласно п.8 положения `Правил предоставления гостиничных услуг в РФ`. Администратор службы приема и
                  размещения оформляет регистрационную карту гостя с указанием ФИО гостя, даты заезда-выезда, номера
                  глэмпинга, подписью гостя. А так же выдает гостю ключ для доступа в номер глэмпинга. Ключ выдаётся на
                  всё время проживания в глэмпинге.
                </li>
                <li>
                  Предоставляя документы, гость дает свое согласие глэмпингу на сбор, хранение, обработку и уничтожение
                  своих персональных данных по истечении 2-х лет (в целях, установленных законодательством РФ).
                </li>
                <li>
                  Оплата услуг по проживанию осуществляется по утвержденному администрацией глэмпинга прейскуранту в
                  рублях РФ.
                </li>
                <li>
                  Кассовый чек выдается гостю на руки сразу после оплаты, а счет — при выезде из глэмпинга. При выезде
                  производится полный расчет за предоставленные услуги.
                </li>
                <li>Форма оплаты — наличный и безналичный расчет, кредитная карта.</li>
                <li>
                  Оплата за проживание может взиматься с гостя в 100% объеме до заезда в соответствии с бронированием.
                </li>
                <li>
                  В стоимость проживания в глэмпинге входят следующие услуги: меню подушек,ежедневная уборка со сменой
                  полотенец, вечерняя уборка (подготовка номера ко сну), ежедневная смена постельного белья, завтрак,
                  пользование зоной центра глэмпинга, пользование сейфом в номере, предоставление халатов, тапочек,
                  набора косметических средств, Wi-Fi Free на всей территории глэмпинга. Без дополнительной оплаты гостю
                  предоставляются следующие услуги: побудка к определенному времени, вызов скорой помощи, аптечка.
                </li>
                <li>
                  За дополнительную плату, согласно действующему прейскуранту, по желанию клиента глэмпинг оказывает
                  следующие услуги: организация трансфера от и до города, городов областного центра; другие
                  дополнительные услуги, согласно действующему прейскуранту. Полная информация об услугах глэмпинга
                  находится на рецепции Службы приема и размещения и в каждом номере – в информационной папке `Папка
                  гостя`;
                </li>
                <li>
                  Плата за проживание в глэмпинге рассчитывается в соответствии с единым расчетным часом — 12 часов
                  текущих суток. В случае задержки выезда плата за дополнительное проживание взимается в следующем
                  порядке: до 14.00 часов после расчетного часа –почасовая оплата, с 11.00 до 17:00 часов после
                  расчетного часа — плата за половину суток, после 17:00 плата взимается за сутки независимо от
                  расчетного часа.
                </li>
              </ol>
            </li>
            <li>
              Обязанности исполнителя по предоставлению услуг:
              <ol>
                <li>Своевременно и в полном объеме предоставить гостям и посетителям глэмпинга оплаченные услуги.</li>
                <li>
                  Обеспечить полное соответствие санитарным и другим нормам качество и безопасность предоставляемых
                  услуг.
                </li>
                <li>Обеспечить конфиденциальность информации о гостях и посетителях глэмпинга.</li>
                <li>
                  При проведении шумных мероприятий на территории глэмпинга после 23.00 часов – принимать все меры для
                  заблаговременного оповещении гостей глэмпинга при бронировании и/или заселении в Глэмпинг на эти даты
                  о таком мероприятии.
                </li>
                <li>
                  Своевременно реагировать на требования гостей и посетителей глэмпинга об устранении неудобств, поломок
                  в оборудовании и технике в апартаментах, на объектах инфраструктуры глэмпинга, других недостатков
                  оказанной услуги.
                </li>
                <li>
                  В случае аварии или выходе из строя какого-либо оборудования в номере и невозможности устранения
                  данной проблемы, глэмпинг предлагает гостю разумную скидку или при наличии свободных номеров
                  альтернативное размещение.
                </li>
                <li>
                  Исполнитель предоставляет без дополнительной оплаты услуги, согласно п.2.7. настоящего Положения, о
                  которых информирует гостя при поселении.
                </li>
                <li>
                  Гость глэмпинга вправе расторгнуть договор на оказание услуги в любое время,в случае необходимости
                  уплатив глэмпингу неустойку. Глэмпинг вправе заключать договор на бронирование мест в глэмпинге путем
                  составления документа, подписанного двумя сторонами, а также путем принятия заявки на бронирование
                  посредством телефонной и иной связи, позволяющей достоверно установить, что заявка исходит от гостя.
                </li>
              </ol>
            </li>
            <li>
              Права гостя и посетителя глэмпинга:
              <ol>
                <li>
                  Пользоваться всеми услугами глэмпинга в установленном режимом работы объектов глэмпинга и
                  интервале(кроме услуги по проживанию для посетителя глэмпинга).
                </li>
                <li>
                  Получать информацию по работе объектов глэмпинга, производить предварительный заказ услуг, получать
                  информацию о состоянии своего счета (фолио Гостя), производить промежуточный платеж.
                </li>
                <li>
                  Гость глэмпинга имеет право проводить в глэмпинг лиц, посещающих гостя глэмпинга, с предоставлением
                  документа, удостоверяющего личность.
                </li>
                <li>Регистрационная карта оформляется при наличии документа, удостоверяющего личность.</li>
                <li>
                  Обращаться к Администратору и Администрации глэмпинга по вопросам качества оказанных услуг и при
                  необходимости составлять претензию по качеству оказанных услуг.
                </li>
                <li>Глэмпинг не предоставляет льготы для проживания.</li>
              </ol>
            </li>
            <li>
              Обязанности гостя и посетителя глэмпинга:
              <ol>
                <li>
                  В период нахождения в глэмпинге соблюдать морально-этические нормы, воздерживаться в местах массового
                  отдыха гостей и посетителей глэмпинга от чрезмерного употребления алкоголя и нецензурных выражений.
                  Уважать право других лиц на отдых, не оскорблять действиями и словами персонал глэмпинга.
                </li>
                <li>
                  Соблюдать Правила пользования объектами инфраструктуры глэмпинга, прописанными на каждый объект.
                </li>
                <li>Использовать оснащение и инвентарь номера только согласно их функциональному назначению.</li>
                <li>Соблюдать правила противопожарной безопасности на всех объектах глэмпинга.</li>
                <li>
                  В период с 23 часов вечера до 08 часов утра соблюдать тишину и не создавать неудобства другим гостям и
                  посетителям глэмпинга.
                </li>
                <li>Своевременно оплачивать счет за оказанные услуги.</li>
                <li>
                  Беречь имущество глэмпинга, в том числе переданное во временное пользование. В случае утраты или
                  повреждения имущества возмещать ущерб, а также нести ответственность за иные нарушения.
                </li>
                <li>
                  О любой задержке выезда своевременно проинформировать Службу приема и размещения. В случае
                  максимальной загрузки номерного фонда глэмпинга имеет право отказать в возможности позднего выезда или
                  продления проживания. Гость обязан в таком случае покинуть номер к моменту наступления расчетного
                  часа.
                </li>
                <li>
                  О любом выезде до конечной даты бронирования гость обязан своевременно, но не позднее, чем за 24 ч.
                  проинформировать Службу приема и размещения глэмпинга.
                </li>
                <li>
                  При выявлении у гостя инфекционного заболевания или при подозрении на такое заболевание гость обязан
                  немедленно пройти лабораторное обследование и медицинское наблюдение или лечение и в случае, если он
                  представляет опасность для окружающих, обязательную госпитализацию или изоляцию (ст. 3 33 Ф3 `О
                  санитарно-эпидемиологическом благополучии населения` от 30.03.1999. # 52—Ф3).Отказ гостя предоставить
                  документ из лечебного учреждения подтверждающий отсутствие опасности для окружающих от инфекционного
                  заболевания или подозрения на него дает основание глэмпингу прекратить действие договора на оказание
                  услуг немедленно.
                </li>
                <li>
                  В день выезда гость должен сообщить службе размещения о готовности освободить номер и подойти на
                  рецепцию глэмпинга для сдачи ключей и номера глэмпинга, оплаты дополнительных услуг.
                </li>
                <li>
                  Курение в любых помещениях глэмпинга строго запрещено. При нарушении данного режима администрация
                  глэмпинга вправе оштрафовать гостя, штраф составляет 3 000,00 рублей.
                </li>
              </ol>
            </li>
            <li>
              На территории глэмпинга запрещается:
              <ol>
                <li>
                  Разводить костры в непредусмотренных для этого местах и запускать фейерверки (при несанкционированных
                  действиях гостей или посетителей глэмпинга) .
                </li>
                <li>
                  Проводить на территорию глэмпинга своих посетителей без предоставления документа, удостоверяющего
                  личность гостя.
                </li>
                <li>
                  Ношение и хранение оружия, взрывчатых и легковоспламеняющихся, едких и ядовитых веществ. Гости,
                  имеющие право по роду своей деятельности на ношение и хранение оружия, обязаны предоставить документы,
                  удостоверяющие данное право по требованию Администрации глэмпинга, Под оружием следует понимать
                  средства, указанные в законе РФ `Об оружии` .
                </li>
                <li>Хранение и несанкционированное использование пиротехнических изделий.</li>
                <li>Проживание с животными разрешается только при согласовании с Администрацией глэмпинга.</li>
              </ol>
            </li>
            <li>
              Ответственность гостя, посетителя глэмпинга и права глэмпинга:
              <ol>
                <li>
                  В случае грубого нарушения правил общественной безопасности и общественного порядка, общественной
                  нравственности или правил пожарной безопасности со стороны гостя или посетителя глэмпинга, глэмпинг
                  имеет право прекратить действие договора на оказание услуг немедленно, составить акт по данному
                  нарушению с приглашением при необходимости сотрудников правоохранительных органов.
                </li>
                <li>
                  {' '}
                  В случае прекращения действия договора на оказание услуг в соответствии с п. 7.1.‚ возврат денег за
                  оплаченные, но нереализованные услуги, гостю или посетителю глэмпинга осуществляется в соответствии с
                  законодательством РФ.
                </li>
                <li>
                  Глэмпинг не несет ответственности за здоровье гостя или посетителя глэмпинга в случае употребления им
                  напитков и продуктов, приобретенных вне территории глэмпинга.
                </li>
                <li>
                  Исполнитель не несет ответственность за сохранность не помещенных на хранение в сейф документов,
                  денег, иных валютных ценностей и других драгоценных вещей.
                </li>
                <li>
                  Уполномоченные сотрудники глэмпинга вправе заходить в помещение номера, во время отсутствия гостя, в
                  случае производственной необходимости.
                </li>
                <li>
                  Глэмпинг в соответствие с ФЗ `О персональных данных` от 27.07.2006 N 152-ФЗ, запрашивает у гостя
                  согласие на обработку его персональных данных; согласие на совершение действий, предусмотренных п.п. 3
                  ст. 3 Федерального закона `О персональных данных`.
                </li>
              </ol>
            </li>
            <li>
              Ответственность глэмпинга, гостя и посетителя глэмпинга:
              <ol>
                <li>
                  Гость или посетитель глэмпинга, при обнаружении недостатков оказанной услуги вправе по своему выбору
                  потребовать безвозмездного устранения недостатков, соответствующего уменьшения цены за оказанную
                  услугу.
                </li>
                <li>
                  Гость или посетитель глэмпинга вправе расторгнуть договор на предоставление услуг и потребовать
                  полного возмещения убытков, если Глэмпинг не предоставил услугу и/или не устранил недостатки услуги в
                  оговоренный (разумный) срок.
                </li>
                <li>
                  Гость или посетитель глэмпинга также вправе расторгнуть договор, если он обнаружил существенные
                  недостатки в оказанной услуге или иные существенные отступления от условий договора.
                </li>
                <li>
                  Глэмпинг должен устранить недостатки оказанной услуги в течение 48 часов с момента предъявления
                  соответствующего требования.
                </li>
                <li>
                  Требования гостя или посетителя глэмпинга об уменьшении цены оказанной услуги, а также о возмещении
                  убытков, причиненных расторжением договора на предоставление услуг, подлежат удовлетворению в течение
                  25 рабочих дней со дня предъявления соответствующего требования.
                </li>
                <li>
                  Гость или посетитель глэмпинга вправе потребовать также полного возмещения убытков, причиненных ему в
                  связи с недостатком оказанной услуги. Убытки возмещаются в сроки, установленные для удовлетворения
                  соответствующих требований потребителя (на основании Закона РФ `О защите прав потребителя`).
                </li>
                <li>
                  Глэмпинг в соответствии с законодательством Российской Федерации несет ответственность за вред,
                  причиненный жизни, здоровью или имуществу гостя или посетителя глэмпинга вследствие недостатков при
                  оказании услуг, а также компенсирует моральный вред, причиненный нарушением прав потребителя.
                </li>
                <li>
                  Гость или посетитель глэмпинга в соответствии с законодательством Российской Федерации возмещает ущерб
                  в случае утраты или повреждения имущества глэмпинга (согласно Прейскуранту глэмпинга), а также несет
                  ответственность за иные нарушения, в том числе за грубое нарушение правил проживания и пребывания в
                  глэмпинге.
                </li>
              </ol>
            </li>
            <li>
              Правила пожарной безопасности:
              <ol>
                <li>Не пользуйтесь электронагревательными приборами.</li>
                <li>Не накрывайте включенные лампы предметами из сгораемых материалов.</li>
                <li>Курение на территории глэмпинга запрещено.</li>
                <li>Не храните в номере пожароопасные вещества и материалы.</li>
                <li>Уходя из номера, выключайте свои электроприборы.</li>
              </ol>
            </li>
            <li>
              Правила поведения на воде:
              <ol>
                <li>
                  Перед тем, как отправляться на озеро, убедитесь, что прогноз погоды благоприятный. Если есть штормовое
                  предупреждение, отложите плавание на другой день.
                </li>
                <li>
                  Плавайте на виду у других людей. Если возможно, плывите вместе с другом или в группе. При несчастном
                  случае это может спасти вам жизнь.
                </li>
                <li>
                  Если вы не умеете плавать, научитесь или не пытайтесь плавать на глубокой воде. Возьмите уроки
                  плавания или носите надувной матрас для плавания в более мелкой воде.
                </li>
                <li>
                  Никогда не употребляйте алкоголь на озере. Алкоголь уменьшает вашу координацию и реакцию, что может
                  привести к несчастному случаю.
                </li>
                <li>Если вы пришли на озеро с детьми, убедитесь, что они находятся под вашим наблюдением.</li>
                <li>
                  Если вы планируете использовать лодку, ознакомьтесь с правилами безопасности на лодках и всегда носите
                  спасательные жилеты.
                </li>
                <li>
                  Если вы планируете посетить пляж на озере, соблюдайте правила пляжа, используйте только разрешенные
                  зоны для купания и не пытайтесь переплывать ограждения безопасности, если они имеются.
                </li>
                <li>Сохраняйте озеро чистым, не выбрасывайте мусор и не сливайте другие загрязнители в воду.</li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
};
