import { useEffect } from 'react';

import { HouseContent } from '../../components/house-content/house-content';
import { PhotoHouse1, PhotoHouse2, PhotoHouse3, PhotoHouse4, PhotoHouse5 } from '../../constants/house-photo';

export const HousesPage = () => {
  useEffect(() => {
    document.title = 'Almarana - бронирование дома';
  });

  return (
    <div className='houses-wrapper'>
      <HouseContent photoArray={PhotoHouse1} title='Апартаменты # 1' bed='+ диван' capacity={3} price='4000' apartment='1'/>
      <HouseContent photoArray={PhotoHouse2} title='Апартаменты # 2' bed={' '} capacity={2} price='3000' apartment='2'/>
      <HouseContent photoArray={PhotoHouse3} title='Апартаменты # 3' bed={' '} capacity={1} price='2500' apartment='3'/>
      <HouseContent photoArray={PhotoHouse4} title='Апартаменты # 4' bed='+ диван' capacity={3} price='4400' apartment='4'/>
      <HouseContent photoArray={PhotoHouse5} title='Апартаменты # 5' bed='+ диван' capacity={4} price='5000' apartment='5'/>
    </div>
  );
};
