import apartment from '../../assets/houses/house_1/house_1_2.jpg';
import spa from '../../assets/img/offers/bathhouse-spa.jpg';
import fishing from '../../assets/img/offers/fishing.jpg';
import forest from '../../assets/img/offers/forest.jpg';
import museumFairyTail from '../../assets/img/tours/1-160.jpg';
import museum from '../../assets/img/tours/1300361721.jpg';
import bee from '../../assets/img/tours/paseka2.jpg';

import './package-tours.scss';

export const PackageToursPage = () => (
  <div className='package-tours-wrapper'>
    <div className='title-wrapper'>
      <div className='title'>Пакетные туры</div>
      <p>
        Возможно, вы думаете не только насладится природным великолепием, но и узнать о нашем крае много интересного.
      </p>
    </div>
    <div className='tour-wrapper'>
      <div className='tour-title'>Пакет `Изочи-экскурсия` 1 день</div>
      <div className='tour'>
        <div className='text-block'>
          <div className='text-block-title'>Эко-тропа + экскурсия + баня</div>
          <p>
            <b>Пакет `Изочи-экскурсия`</b> представляет собой уникальное путешествие, сочетающее экологическую прогулку по сосновому
            лесу, посещение музея и релаксацию в бане. <br />
            Этот тур идеально подходит для тех, кто хочет насладиться красотой природы, узнать больше об истории и
            культуре местности, а также расслабиться и ощутить все блага бани.
          </p>
          <ul>
            Программа тура:
            <li>
              1. Эко-тропа по сосновому лесу: Вы отправитесь в увлекательную прогулку по живописным тропинкам леса
              Большой Иван. Вас окружит запах сосновых деревьев, свежий воздух и пение птиц. Опытный экскурсовод
              расскажет вам о флоре и фауне леса, его экосистеме и значении сохранения природы.
            </li>
            <li>
              {' '}
              2. Экскурсия в музей: После прогулки вас ждет посещение местного музея, где вы сможете познакомиться с
              богатой историей этой местности. Вы узнаете о древних обычаях и традициях, о важных событиях и значимых
              личностях, связанных с озером Большой Иван. Музейные экспонаты и интерактивные выставки позволят вам
              окунуться в прошлое и лучше понять настоящее.{' '}
            </li>
            <li>
              3. Баня: После насыщенного дня вы сможете полностью расслабиться в местной бане. Баня в России имеет
              особое значение и считается частью национальной культуры. Здесь вы сможете ощутить благотворное влияние
              пара и русского парения на организм, а также попробовать различные традиционные процедуры, такие как
              веники, массаж и чаепитие с травяными чаями. Изочи-экскурсия предлагает вам гармоничное сочетание
              активного времяпрепровождения на свежем воздухе, культурного обогащения и релаксации. Вы сможете оценить
              красоту природы, расширить свои знания о местной истории и насладиться традиционной русской баней. Этот
              тур подходит для людей всех возрастов и интересов, желающих провести время в гармонии с природой и
              познакомиться с местным культурным наследием.
            </li>
          </ul>
        </div>
        <div className='pic-block'>
          <img src={forest} alt='pic' />
          <img src={museum} alt='pic' />
          <img src={spa} alt='pic' />
          <img src={apartment} alt='pic' />
        </div>
      </div>
    </div>
    <div className='tour-wrapper'>
      <div className='tour-title'>Пакет `Сказочный край` 2 дня </div>
      <div className='tour'>
        <div className='text-block'>
          <div className='text-block-title'>1 день: эко-тропа + баня. 2 день: экскурсия + музей сказок</div>
          <p>
            <b>Пакет `Сказочный край`</b> экскурсия `Сказочный край` сочетает в себе увлекательную прогулку по сосновому лесу,
            посещение музея русских сказок, экскурсию в музей и релаксацию в бане. Этот тур пригодится для людей,
            желающих окунуться в мир русских сказок, насладиться природной красотой и расслабиться в бане.
          </p>
          <ul>
            Программа тура:
            <li>
              1. Эко-тропа по сосновому лесу: Вы отправитесь в увлекательную прогулку по живописным тропинкам леса
              Большой Иван. Вас окружит запах сосновых деревьев, свежий воздух и пение птиц. Опытный экскурсовод
              расскажет вам о флоре и фауне леса, его экосистеме и значении сохранения природы.
            </li>
            <li>
              2. Баня: После насыщенного дня вы сможете полностью расслабиться в местной бане. Баня в России имеет
              особое значение и считается частью национальной культуры. Здесь вы сможете ощутить благотворное влияние
              пара и русского парения на организм, а также попробовать различные традиционные процедуры, такие как
              веники, массаж и чаепитие с травяными чаями. Изочи-экскурсия предлагает вам гармоничное сочетание
              активного времяпрепровождения на свежем воздухе, культурного обогащения и релаксации. Вы сможете оценить
              красоту природы, расширить свои знания о местной истории и насладиться традиционной русской баней. Этот
              тур подходит для людей всех возрастов и интересов, желающих провести время в гармонии с природой и
              познакомиться с местным культурным наследием.
            </li>
            <li>
              3. Экскурсия в музей: вас ждет посещение местного музея, где вы сможете познакомиться с богатой историей
              этой местности. Вы узнаете о древних обычаях и традициях, о важных событиях и значимых личностях,
              связанных с озером Большой Иван. Музейные экспонаты и интерактивные выставки позволят вам окунуться в
              прошлое и лучше понять настоящее.
            </li>
            <li>
              3. Музей русских сказок: Вам предстоит посетить уникальный музей, посвященный русским сказкам. Вы
              познакомитесь с героями и сюжетами известных сказок, узнаете о русской народной культуре и традициях.
              Музей предлагает интерактивные выставки, мастер-классы по русским народным ремеслам и возможность
              перенестись в волшебный мир сказок.
            </li>
          </ul>
        </div>
        <div className='pic-block'>
          <img src={forest} alt='pic' />
          <img src={spa} alt='pic' />
          <img src={museum} alt='pic' />
          <img src={museumFairyTail} alt='pic' />
        </div>
      </div>
    </div>
    <div className='tour-wrapper'>
      <div className='tour-title'>Пакет `Природная гармония` 2 дня </div>
      <div className='tour'>
        <div className='text-block'>
          <div className='text-block-title'>1 день: рыбалка + баня. 2 день: музей сказок + пасека</div>
          <p>
            <b>Пакет `Природная гармония`</b> предлагает вам возможность насладиться активным отдыхом на свежем воздухе, погрузиться в мир русских сказок и познакомиться с удивительной работой пчел. А после насыщенных дней вы сможете расслабиться в бане и насладиться ее целебными свойствами. Этот пакет подходит для людей, желающих сочетать активный отдых, культурное погружение и релаксацию в природной обстановке.
          </p>
          <ul>
            Программа тура:
            <li>
              1. Рыбалка: Вы отправитесь на увлекательную рыбалку на берегу озера Большой Иван. Вас ожидает возможность поймать разнообразных рыбных видов и насладиться тишиной и красотой природы вокруг. Опытный рыболов расскажет вам о местных рыболовных традициях и поделится советами для успешной ловли.
            </li>
            <li>
              2. Баня: По окончании рыбалки вы сможете расслабиться в бане. Тепло и пар помогут вам расслабиться, снять усталость и напряжение после активного дня. Вы также сможете попробовать различные процедуры, такие как веники, массаж и насладиться чашечкой травяного чая.
            </li>
            <li>
              3. Музей сказок: Вам предстоит посетить уникальный музей русских сказок. Вы окунетесь в мир волшебства и фантазии, познакомитесь с героями и сюжетами известных русских сказок. Музей предлагает интерактивные выставки, где вы сможете почувствовать себя частью сказочного мира.
            </li>
            <li>
              3. Пасека: После посещения музея сказок вы отправитесь на экскурсию в пасеку. Здесь вы сможете узнать о процессе производства меда, о труде пчел и их роли в природе. Опытный пасечник расскажет вам о пасекарской культуре, а также предоставит возможность попробовать свежий мед прямо с пасеки.
            </li>
          </ul>
        </div>
        <div className='pic-block'>
          <img src={fishing} alt='pic' />
          <img src={spa} alt='pic' />
          <img src={museumFairyTail} alt='pic' />
          <img src={bee} alt='pic' />
        </div>
      </div>
    </div>
  </div>
);
