/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import facebook from '../../assets/icon/icons8-facebook.svg';
import instagram from '../../assets/icon/icons8-instagram.svg';
import linkedin from '../../assets/icon/icons8-linkedin.svg';
import vk from '../../assets/icon/icons8-vk-com.svg';
import whatsapp from '../../assets/icon/icons8-whatsapp.svg';

import './footer.scss';

export const Footer: FC = () => (
  <footer className='footer'>
    <div className='contact-and-rules'>
      <div className='contact'>
        <h4>Связь с нами</h4>
        <div className='phone'>
          <p>
            <b>Телефон для связи: &nbsp;</b>
          </p>
          <a href='tel:+79009909005' className='phone-number'>
            7 (900) 990-90-05
          </a>
        </div>
        <div className='email'>
          <p>
            <b>Почта: &nbsp;</b>
          </p>
          <a href='mailto:almarana.club@gmail.com' className='email-box'>
            almarana.club@gmail.com
          </a>
        </div>
      </div>
      <div className='rules'>
        <h4>Правила</h4>
        <a href=''>Конфиденциальность и файлы cookies</a>
        <NavLink to='/terms'>Условия и положения</NavLink>
      </div>
    </div>
    <div className='copyright-and-social'>
      <span className='footer-text'>© 2023 Almarana. All rights reserved.</span>
      <div className='social'>
        <a href='#' className='social-link'>
          <img className='social-link-img' src={facebook} alt='facebook' />
        </a>
        <a href='#' className='social-link'>
          <img className='social-link-img' src={instagram} alt='instagram' />
        </a>
        <a href='#' className='social-link'>
          <img className='social-link-img' src={vk} alt='vk' />
        </a>
        <a href='#' className='social-link'>
          <img className='social-link-img' src={linkedin} alt='linkedin' />
        </a>
        <a href='#' className='social-link'>
          <img className='social-link-img' src={whatsapp} alt='whatsapp' />
        </a>
      </div>
    </div>
  </footer>
);
