import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Burger } from '../burger/burger';

import './header.scss';

export const Header = () => {
  const [bigHeader, setBigHeader] = useState(true);
  const [headerType, setHeaderType] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setHeaderType(true);
    } else {
      setHeaderType(false);
    }
  }, [headerType, location.pathname]);

  const changeHeader = () => {
    if (window.scrollY >= 70) {
      setBigHeader(false);
    } else {
      setBigHeader(true);
    }
  };

  window.addEventListener('scroll', changeHeader);

  return (
    <header className={headerType ? 'header-wrapper' : 'header-wrapper other'}>
      <Burger />
      <div className={bigHeader ? 'logo' : 'hide-logo'}>
        <NavLink to='/' className='logo-title'>
          Almarana
        </NavLink>
      </div>
      <div className={bigHeader ? 'navigation-wrapper' : 'navigation-wrapper top'}>
        {/* <NavLink to='/' className='page-link'>
          Главная
        </NavLink> */}
        <NavLink to='/experience' className='page-link'>Впечатления</NavLink>
        <NavLink to='/package-tours' className='page-link'>Пакетные туры</NavLink>
        <NavLink to='/houses' className='page-link'>
          Дома
        </NavLink>
        <NavLink to='/occasions' className='page-link'>События</NavLink>
        <NavLink to='/offers' className='page-link'>Предложения</NavLink>
        <NavLink to='/about-us' className='page-link'>О нас</NavLink>
        {/* <NavLink className='page-link'>Правила</NavLink> */}

        {/* <NavLink to='/aboutUs' className='page-link'>
          О нас
        </NavLink> */}
        {/* <NavLink to='/terms' className='page-link'>
          Правила
        </NavLink> */}
        {/* <NavLink to='/contacts' className='page-link'>
          Контакты
        </NavLink> */}
        {/* Almarana GLAMPING Пакетные ТУРЫ ДОМА */}
      </div>
    </header>
  );
};
