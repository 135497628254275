import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { Layout } from './components/layout/layout';
// import { ROUTES } from './constants/routes';
import { AboutUsPage } from './pages/about-us';
import { ContactPage } from './pages/contact';
import { ExperiencePage } from './pages/experience/experience';
import { HomePage } from './pages/home';
import { HousesPage } from './pages/houses';
import { OccasionsPage } from './pages/occasions/occasions';
import { OffersPage } from './pages/offers/offers';
import { PackageToursPage } from './pages/package-tours/package-tours';
import { TermsPage } from './pages/terms/terms';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<HomePage />} />
          <Route path='terms' element={<TermsPage />} />
          <Route path='offers' element={<OffersPage />} />
          <Route path='houses' element={<HousesPage />} />
          <Route path='about-us' element={<AboutUsPage />} />
          <Route path='contacts' element={<ContactPage />} />
          <Route path='occasions' element={<OccasionsPage />} />
          <Route path='experience' element={<ExperiencePage />} />
          <Route path='package-tours' element={<PackageToursPage />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);
