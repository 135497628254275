/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import bathhouse from '../../assets/img/offers/bathhouse-spa.jpg';
import boat from '../../assets/img/offers/boat-lake.jpg';
import fishing from '../../assets/img/offers/fishing.jpg';
import forest from '../../assets/img/offers/forest.jpg';
import games from '../../assets/img/offers/inclined-wooden-block-tower-jenga-game-with-hand-risk-concept-free-photo.jpg';
import cycle from '../../assets/img/offers/rent-cycle.jpg';

import './block-our-offers.scss';
import './swiper-style.scss';

import 'swiper/scss/free-mode';
import 'swiper/scss';
import 'swiper/scss/navigation';

export const OurOffers = () => (
    <div>
      <div className='content-our-offers'>
        <div className='title-our-offers'>
          <p>Посмотрите наши предложения</p>
        </div>

        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          breakpoints={{
            '@0.00': {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            '@0.33': {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            '@0.66': {
              slidesPerView: 3,
              spaceBetween: 0,
            },
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className='mySwiper'
        >
          <SwiperSlide>
            <img src={bathhouse} alt='sl1' className='stat' />
            <div className='slide-overlay'>
              <h2>Баня</h2>
              <NavLink to='/offers?blockId=block1' className='primary-button'>
                Перейти 1
              </NavLink>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={forest} alt='sl1' />
            <div className='slide-overlay'>
              <h2>Прогулки</h2>
              <NavLink to='/offers?blockId=block4' className='primary-button'>
                Перейти 4
              </NavLink>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={games} alt='sl1' />
            <div className='slide-overlay'>
              <h2>Настольные игры</h2>
              <NavLink to='/offers?blockId=block5' className='primary-button'>
                Перейти 5
              </NavLink>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={cycle} alt='sl1' />
            <div className='slide-overlay'>
              <h2>Велопрокат</h2>
              <NavLink to='/offers?blockId=block6' className='primary-button'>
                Перейти 6
              </NavLink>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={boat} alt='sl1' />
            <div className='slide-overlay'>
              <h2>Аренда лодок</h2>
              <NavLink to='/offers?blockId=block2' className='primary-button'>
                Перейти 2
              </NavLink>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={fishing} alt='sl1' />
            <div className='slide-overlay'>
              <h2>Рыбалка</h2>
              <NavLink to='/offers?blockId=block3' className='primary-button'>
                Перейти 3
              </NavLink>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
