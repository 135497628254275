import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import isochi from '../../assets/img/occasions/bel-cam-a-loveletter-to-italy03_960x835.jpg';
import celebration from '../../assets/img/occasions/bel-cam-lbt02_960x835.jpg';
import wed from '../../assets/img/occasions/mqs-occ-wedding02.jpg';

import './occasions.scss';

export const OccasionsPage = () => {
  const weddingRef = useRef(null);
  const celebrationRef = useRef(null);
  const isochiRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleScrollToWedding = () => {
      const yOffset = weddingRef.current.getBoundingClientRect().top + window.pageYOffset - 200;

      window.scrollTo({ top: yOffset, behavior: 'smooth' });
    };

    const handleScrollToCelebration = () => {
      const yOffset = celebrationRef.current.getBoundingClientRect().top + window.pageYOffset - 200;

      window.scrollTo({ top: yOffset, behavior: 'smooth' });
    };

    const handleScrollToIsochi = () => {
      const yOffset = isochiRef.current.getBoundingClientRect().top + window.pageYOffset - 200;

      window.scrollTo({ top: yOffset, behavior: 'smooth' });
    };

    if (location.hash === '#wedding') {
      handleScrollToWedding();
    } else if (location.hash === '#celebration') {
      handleScrollToCelebration();
    } else if (location.hash === '#isochi') {
      handleScrollToIsochi();
    }
  }, [location]);

  return (
    <div className='occasions-wrapper'>
      <div className='title-wrapper'>
        <div className='title'>ПРАЗДНИК, КОТОРЫЙ НУЖНО ПОМНИТЬ</div>
        <p>
          Возможно, вы ищете идеи для проведения свадьбы, планируете важную корпоративную конференцию, организуете
          торжественное мероприятие или просто ищете романтический столик для двоих. В любом случае, с нашей талантливой
          командой экспертов вы в надежных руках. Мы перенесем ваших гостей в волшебный мир, создав особые моменты,
          которые вы никогда не забудете
        </p>
        <div className='links'>
          <Link to='/occasions#wedding'>Свадьбы</Link>
          <Link to='/occasions#celebration'>Праздники</Link>
          <Link to='/occasions#isochi'>Путешествия</Link>
        </div>
      </div>
      <div className='occasion-wrapper' ref={weddingRef} id='wedding'>
        <div className='occasion-title'>Идеальные свадьбы</div>
        <div className='occasion'>
          <div className='text-block'>
            <div className='text-block-title'>Деревенские свадьбы</div>
            <p>
              Создайте свою собственную сельскую идиллию среди деревьев и цветов в тихом саду. Пройдите по проходу в
              нашем историческом убежище в самом сердце Изочи. Будь то зимний роман у ревущего огня или сон в летнюю
              ночь, отель Алмарана вызывает в воображении определенную романтику, перед которой невозможно устоять.
            </p>
          </div>
          <div className='pic-block'>
            <img src={wed} alt='wedding-pic' />
          </div>
        </div>
      </div>
      <div className='occasion-wrapper' ref={celebrationRef} id='celebration'>
        <div className='occasion-title'>Яркие праздники</div>
        <div className='occasion'>
          <div className='text-block'>
            <div className='text-block-title'>Любое событие стильно</div>
            <p>
              Добавим изюминки вашему мероприятию. Каким бы ни было событие, Алмарана гарантирует, что вы отметите его в
              великолепном стиле — планируете ли вы что-то впечатляющее или просто красочный ужин на двоих. Алмарана это
              веселье, сказочность и праздничность.
            </p>
          </div>
          <div className='pic-block'>
            <img src={celebration} alt='celebration-pic' />
          </div>
        </div>
      </div>
      <div className='occasion-wrapper' ref={isochiRef} id='occasions#isochi'>
        <div className='occasion-title'>Путешествия</div>
        <div className='occasion'>
          <div className='text-block'>
            <div className='text-block-title'>Изочи, побережье Большого Ивана</div>
            <p>
              Отправляйтесь на поиски сокровищ по тропам, пахнущим лимоном. Покорите волны побережья Изочи на роскошной
              лодке. Или научиться готовить местную кухню. В этом отеле, расположенном на вершине утеса, имеется
              множество стимулирующих мероприятий.
            </p>
          </div>
          <div className='pic-block'>
            <img src={isochi} alt='isochi-pic' />
          </div>
        </div>
      </div>
    </div>
  );
};
