/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import square from '../../assets/icon/ho/icons8-3d-design-64.png';
import safe from '../../assets/icon/ho/icons8-safe-box-64.png';
import kitchen from '../../assets/icon/ho/icons8-stove-64.png';
import terrace from '../../assets/icon/ho/icons8-terrace-64.png';
import tv from '../../assets/icon/ho/icons8-tv-monitor-64.png';
import wifi from '../../assets/icon/ho/icons8-wifi-64.png';
import airСonditioner from '../../assets/icon/ho/icons8-кондиционер-64.png';
import noSmoking from '../../assets/icon/ho/icons8-курение-запрещено-64.png';
import lakeView from '../../assets/icon/ho/icons8-озеро-50.png';
import { ModalContactUs } from '../form/form';

import './house-content.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/thumbs';

export const HouseContent = ({ photoArray, title, bed, capacity, price, apartment }) => {
  const [activeThumb, setActiveThumb] = useState(null);
  const [modalActive, setModalActive] = useState(false);

  const openModal = () => {
    setModalActive(true);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='house-wrapper'>
      <div className='slider-wrapper'>
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={true}
          modules={[Navigation, Thumbs]}
          grabCursor={true}
          thumbs={{ swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null }}
          className='slider-big'
        >
          {photoArray.map((item) => (
            <SwiperSlide>
              <img src={item} alt='slider images' />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setActiveThumb}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          modules={[Navigation, Thumbs]}
          className='slider-mini'
        >
          {photoArray.map((item) => (
            <SwiperSlide>
              <div className='slider-mini-wrapper'>
                <img src={item} alt='product images' />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='description-wrapper'>
        <p className='title'>{title}</p>
        {screenWidth > 480 ? (
          <table>
            <thead>
              <tr>
                <th>Тип апартаментов</th>
                <th>Вместимость</th>
                <th>Цена за сутки</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className='house-description'>
                    <p className='title'>Апартаменты с 1 спальней</p>
                    <p className='sub-title'>Спальня: двуспальная кровать {bed}</p>
                    {/* <p className='sub-title'>Гостинная: диван-кровать</p> */}
                    <div className='description-points'>
                      <div className='description-point'>
                        <img src={square} alt='' className='description-img' />
                        <p>16 кв.м</p>
                      </div>
                      <div className='description-point'>
                        <img src={kitchen} alt='' className='description-img' />
                        <p>Собственная кухня</p>
                      </div>
                      <div className='description-point'>
                        <img src={lakeView} alt='' className='description-img' />
                        <p>Вид на озеро</p>
                      </div>
                      <div className='description-point'>
                        <img src={tv} alt='' className='description-img' />
                        <p>Телевизор</p>
                      </div>
                      <div className='description-point'>
                        <img src={terrace} alt='' className='description-img' />
                        <p>Терраса</p>
                      </div>
                      <div className='description-point'>
                        <img src={wifi} alt='' className='description-img' />
                        <p>Бесплатный Wi-Fi</p>
                      </div>
                      <div className='description-point'>
                        <img src={safe} alt='' className='description-img' />
                        <p>Сейф</p>
                      </div>
                      <div className='description-point'>
                        <img src={airСonditioner} alt='' className='description-img' />
                        <p>Кондиционер</p>
                      </div>
                      <div className='description-point'>
                        <img src={noSmoking} alt='' className='description-img' />
                        <p>Курение запрещено</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td className='description-table'>{capacity} чел</td>
                <td className='description-table'>{price}р</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className='wrapper-button-book'>
                  <button type='button' onClick={openModal} className='button-book'>
                    Бронировать
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Тип апартаментов</th>
                <td>
                  <div className='house-description'>
                    <p className='title'>Апартаменты с 1 спальней</p>
                    <p className='sub-title'>Спальня: двуспальная кровать {bed}</p>
                    <div className='description-points'>
                      <div className='description-point'>
                        <img src={square} alt='' className='description-img' />
                        <p>16 кв.м</p>
                      </div>
                      <div className='description-point'>
                        <img src={kitchen} alt='' className='description-img' />
                        <p>Собственная кухня</p>
                      </div>
                      <div className='description-point'>
                        <img src={lakeView} alt='' className='description-img' />
                        <p>Вид на озеро</p>
                      </div>
                      <div className='description-point'>
                        <img src={tv} alt='' className='description-img' />
                        <p>Телевизор</p>
                      </div>
                      <div className='description-point'>
                        <img src={terrace} alt='' className='description-img' />
                        <p>Терраса</p>
                      </div>
                      <div className='description-point'>
                        <img src={wifi} alt='' className='description-img' />
                        <p>Бесплатный Wi-Fi</p>
                      </div>
                      <div className='description-point'>
                        <img src={safe} alt='' className='description-img' />
                        <p>Сейф</p>
                      </div>
                      <div className='description-point'>
                        <img src={airСonditioner} alt='' className='description-img' />
                        <p>Кондиционер</p>
                      </div>
                      <div className='description-point'>
                        <img src={noSmoking} alt='' className='description-img' />
                        <p>Курение запрещено</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Вместимость</th>
                <td className='description-table'>{capacity} чел</td>
              </tr>
              <tr>
                <th>Цена за сутки</th>
                <td className='description-table'>{price}р</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <button type='button' onClick={openModal} className='btn-book'>
                  Бронировать
                </button>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <ModalContactUs active={modalActive} setActive={setModalActive} apartment={apartment} />
    </div>
  );
};
