/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/media-has-caption */

import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import slide1 from '../../assets/houses/house_1/house_1_1.jpg';
import slide2 from '../../assets/houses/house_1/house_1_2.jpg';
import slide3 from '../../assets/houses/house_1/house_1_3.jpg';
import activity_bicycle from '../../assets/img/activity/activity_bicycle.webm';
import activity_forest from '../../assets/img/activity/activity_forest.webm';
import activity_games from '../../assets/img/activity/activity_games.webm';
import backgroundVideo from '../../assets/img/preview-3.webm';
import { GuestExperience } from '../../components/block-guest-experience/block-guest-experience';
import { OurOffers } from '../../components/block-our-offers/block-our-offers';

import './home.scss';

// import './swiper-style.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';

export const HomePage = () => {
  useEffect(() => {
    document.title = 'Almarana - Главная';
  });

  return (
    <div className='main-content-wrapper'>
      <div className='main-content'>
        <div className='background-cover'>
          <video
            src={backgroundVideo}
            preload='true'
            // eslint-disable-next-line react/jsx-boolean-value
            autoPlay
            loop={true}
            muted={true}
            className='video-settings'
          />{' '}
          <div className='message-window'>
            <p className='title'>Бронируй сейчас!</p>
            <NavLink to='/houses'>
              <button type='button' className='primary-button'>
                Выбрать дом
              </button>
            </NavLink>
          </div>
        </div>

        <div className='guest-experience'>
          <GuestExperience />
        </div>
        <div>
          <OurOffers />
        </div>

        {/* <div className='content-our-houses'>
          <div className='title-our-houses'>
            <p>Проживание</p>
          </div>

          <Swiper
            slidesPerView={3}
            spaceBetween={100}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            mousewheel={true}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className='mySwiper'
          >
            <SwiperSlide>
              <img src={slide1} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide2} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide3} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide1} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide1} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide2} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide3} alt='sl1' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide1} alt='sl1' />
            </SwiperSlide>
          </Swiper>
          <div className='booking-button'>
            <NavLink to='/houses'>
              <button type='button' className='primary-button'>
                Выбрать дом
              </button>
            </NavLink>
          </div>
        </div> */}
        {/* <div className='content-activities'>
          <div className='title-activities'>
            <p>активности</p>
          </div>
          <div className='activities'>
            <div className='activity activity_one'>
              <div className='text'>
                <p className='text-title'>Прокат велосипедов</p>
                <p className='text-description'>Возможность изучить окружающую природу вблизи и чуть дальше</p>
              </div>
              <video
                src={activity_bicycle}
                preload='true'
                // eslint-disable-next-line react/jsx-boolean-value
                autoPlay
                loop={true}
                className='video-settings-activity'
              />{' '}
            </div>
            <div className='activity activity_two'>
              <div className='text'>
                <p className='text-title'>Пешие прогулки</p>
                <p className='text-description'>
                  Красивые леса. Чистый воздух. Вдохните полной грудью этот пьянящий воздух
                </p>
              </div>
              <video
                src={activity_forest}
                preload='true'
                // eslint-disable-next-line react/jsx-boolean-value
                autoPlay
                loop={true}
                className='video-settings-activity'
              />{' '}
            </div>
            <div className='activity activity_three'>
              <div className='text'>
                <p className='text-title'>Настольные игры</p>
                <p className='text-description'>
                  Играйте вместе. Проводите время вместе. Наслаждайтесь. И не забывайте, что это всего лишь игра
                </p>
              </div>{' '}
              <video
                src={activity_games}
                preload='true'
                // eslint-disable-next-line react/jsx-boolean-value
                autoPlay
                loop={true}
                className='video-settings-activity'
              />{' '}
            </div>
          </div>
        </div> */}
        {/* <div className='content-other'>
          <p>ещё контент</p>
        </div> */}
      </div>
    </div>
  );
};
